<template>
    <div class="announcement-page">
        <div class="back" @click="goBack">
            <img src="../img/icon-back.png">
        </div>
        <div v-if="isLoading" v-loading="loading" class="text-center">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
            <div class="title">
                {{ announcement.title }}
            </div>
            <div class="date">
                {{ announcement.date }}
            </div>
            <div class="desc" v-html="announcement.description"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Announcement',
        data() {
            return {
                id: 0,
                announcement: {},
                isLoading: true,
            }
        },
        mounted () {
            this.id = this.$route.query.id;
            this.getAnnouncement();
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            getAnnouncement() {
                this.$api.requestByGet('announcements').then((result) => {
                    this.isLoading = false;
                    if (result.success == true) {
                        this.announcement = result.data.find((element) => element.id == this.id);
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/announcement.scss';
</style>