<template>
    <div class="view">
        <RouterView />
    </div>
    <BottomNavigation v-if="isShow" />
</template>

<script>
import BottomNavigation from './components/BottomNavigation.vue'
export default {
    name: 'App',
    components: {
        BottomNavigation
    },
    data() {
        return {
            isShow: true,
        }
    },
    watch: {
        $route(newValue, oldValue) {
            var url = ['/', '/facilities', '/redeem', '/profile'];
            if (url.includes(newValue.path)) {
                this.isShow = true;
            } else {
                this.isShow = false;
            }
        },
    },
}
</script>

<style>
.view {
    min-height: 100vh;
}
</style>
