<template>
    <div class="profile">
        <div class="profile-item" v-for="(item, index) in list" :key="index" @click="goTo(item.route)">
            <div class="profile-item-left">
                <font-awesome-icon :icon="item.icon" />
                <div class="title">
                    {{ item.title }}
                </div>
            </div>
            <el-icon><ArrowRight /></el-icon>
        </div>
        <!-- <div class="version">
            v. 1.0.1 (22)
        </div> -->
        <el-dialog class="logout-dialog" v-model="isShowLogoutDialog" center :show-close="false" :close-on-click-modal="false">
            <div class="desc">
                {{ $t("Are you sure you want to log out?") }}
            </div>
            <div class="btn-row">
                <div class="yes" @click="confirmLogout">
                    {{ $t("Yes") }}
                </div>
                <div class="no" @click="isShowLogoutDialog = false">
                    {{ $t("No") }}
                </div>
            </div>
        </el-dialog>

        <el-dialog class="delete-dialog" v-model="isShowDeleteDialog" center :show-close="false" :close-on-click-modal="false">
            <div class="desc">
                {{ $t("Are you sure want to delete your account?") }}
            </div>
            <div class="desc-small">
                {{ $t("If you delete your account, you will permanantly lose your profile, bookings, and points that you may have accumulated. This action cannot be undone.") }} <br/>
                {{ $t("Permanently delete your account.") }}
            </div>
            <div class="btn-row">
                <div class="yes" @click="confirmDelete">
                    {{ $t("Yes") }}
                </div>
                <div class="no" @click="isShowDeleteDialog = false">
                    {{ $t("No") }}
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Profile',
        data() {
            return {
                userId: localStorage.getItem("userId"),
                isShowLogoutDialog: false,
                isShowDeleteDialog: false,
                list: [
                    {
                        icon: 'fa-regular fa-user',
                        title: this.$t("My Account"),
                        route: '/account',
                    },
                    {
                        icon: 'fa-regular fa-bookmark',
                        title: this.$t("My Bookings"),
                        route: '/myBooking',
                    },
                    {
                        icon: 'fa-solid fa-arrow-right-from-bracket',
                        title: this.$t("Log Out"),
                        route: 'logout',
                    },
                    {
                        icon: 'fa-regular fa-trash-can',
                        title: this.$t("Delete Account"),
                        route: 'delete',
                    }
                ],
            }
        },
        methods: {
            goTo(route) {
                if (route == 'logout') {
                    this.isShowLogoutDialog = true;
                } else if (route == 'delete') {
                    this.isShowDeleteDialog = true;
                } else {
                    this.$router.push(route);
                }
            },
            confirmLogout() {
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                this.$router.push("/");
            },
            confirmDelete() {
                this.$api.requestbyDelete('app-user/' + this.userId).then((result) => {
                    if (result.success) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("userId");
                        this.$router.push("/");
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/profile.scss';
</style>