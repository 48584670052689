<template>
    <div class="login">
        <div class="login-ink">
            <img src="../img/ink.png">
        </div>
        <div class="login-mountain">
            <img src="../img/mountain.png">
        </div>
        <div class="container">
            <div class="login-text text-center">
                {{ $t("Welcome to Fairy Park Heritage City") }}
            </div>
            <div class="login-buttons text-center">
                <div class="login-signin" @click="goTo('requestOtp')">
                    {{ $t("sign in") }}
                </div>
                <div class="login-create" @click="goTo('signUp')">
                    {{ $t("create an account") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        methods: {
            goTo(route) {
                this.$router.push(route);
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/login.scss';
</style>