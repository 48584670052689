<template>
    <div class="facilities">
        <div class="title">
            {{ $t("Facilities") }}
        </div>
        <div class="description">
            {{ $t("*The booking guest is to register at the reception in Fairy Park Heritage City. The booking time slot will only be held for an hour, it will automatically be cancelled in case of a no-show. For any enquiries, kindly contact +6016 - 330 6138.") }}
        </div>
        <div v-if="isFacilityLoading" v-loading="loading" class="text-center">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
            <div v-if="length > 0" class="facility-list">
                <div class="facility-item" v-for="item in facilityList" :key="item.id">
                    <img :src="getAssetPath(item.slug)">
                    <div class="facility-text">
                        <div class="facility-title">
                            {{ item.name }}
                        </div>
                        <div class="facility-book" @click="goToBooking(item.id)">
                            {{ $t("Book") }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="pt-4 text-center">
                {{ $t("No data found") }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Facilities',
        data() {
            return {
                isFacilityLoading: true,
                slugList: ['ktv', 'gym', 'kids-pool', 'swimming-pool', 'ping-pong', 'badminton', 'basketball'],
                facilityList: [],
                length: 0,
            }
        },
        mounted () {
            this.getFacility();
        },
        methods: {
            getFacility() {
                this.$api.requestByGet('facilities').then((result) => {
                    this.isFacilityLoading = false;
                    if (result.success) {
                        for (var i = 0; i < result.data.length; i++) {
                            if (this.slugList.includes(result.data[i]['facility_type']['slug'])) {
                                this.facilityList.push(result.data[i]['facility_type']);
                                var index = this.slugList.indexOf(result.data[i]['facility_type']['slug']);
                                if (index >= 0) {
                                    this.slugList.splice(index, 1);
                                }
                            }
                        }
                        this.length = this.facilityList.length;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isFacilityLoading = false;
                    this.$message.error(error.message);
                });
            },
            getAssetPath(slug) {
                var assetPath = "";
                if (slug == 'badminton') {
                    assetPath = require("../img/badminton1.jpg");
                } else if (slug == 'swimming-pool') {
                    assetPath = require("../img/swimming-pool1.jpg");
                } else if (slug == 'basketball') {
                    assetPath = require("../img/basketball1.jpg");
                } else if (slug == 'gym') {
                    assetPath = require("../img/gym1.jpg");
                } else if (slug == 'ktv') {
                    assetPath = require("../img/ktv-01.jpg");
                } else if (slug == 'ping-pong') {
                    assetPath = require("../img/ping-pong-1.jpg");
                } else {
                    assetPath = require("../img/kids-pool1.jpg");
                }

                return assetPath;
            },
            goToBooking(id) {
                this.$router.push({path: '/booking',  query: {'id': id}});
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/facilities.scss';
</style>