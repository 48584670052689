<template>
    <div class="redeem">
        <div class="section-title">
            {{ $t("Redeem") }}
            <div class="add" @click="topup">
                <el-icon><Plus /></el-icon>
            </div>
        </div>
        <div class="points">
            <div>
                {{ $t("My Points") }}
            </div>
            <div class="pt">
                {{ this.userPoint }}
            </div>
        </div>
        <div class="redeem-point-history" @click="goTo('/pointHistory')">
            {{ $t("Point History") }}
        </div>
        <div class="section-title">
            {{ $t("Voucher List") }}
        </div>
        <div class="coming-soon text-center">
            {{ $t("Coming Soon") }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Redeem',
        data() {
            return {
                isLoading: true,
                userId: localStorage.getItem("userId"),
                userPoint: 0,
            }
        },
        mounted () {
            this.getUserPoint();
        },
        methods: {
            topup() {
                window.open("https://crm.fairypark.asia/api/topup?ap=" + this.userId);
            },
            goTo(route) {
                this.$router.push(route);
            },
            getUserPoint() {
                this.$api.requestByGet('user/points').then((result) => {
                    this.isLoading = false;
                    if (result.success) {
                        this.userPoint = result.points;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/redeem.scss';
</style>