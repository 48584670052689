<template>
    <div class="success-dialog">
        <el-dialog v-model="isShowSuccessDialog" center :show-close="false" :close-on-click-modal="false">
            <img src="../assets/img/icon-success.png">
            <div class="title">
                {{ $t("Success!") }}
            </div>
            <div class="desc">
                {{ desc }}
            </div>
            <div class="btn" @click="this.$emit('isShowSuccessDialog', false);">
                {{ $t("Done") }}
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'SuccessDialog',
        props: ["desc"],
        data() {
            return {
                isShowSuccessDialog: true,
            }
        },
    }
</script>

<style lang="scss">
    @import '../assets/styles/base.scss';
    .el-dialog {
        width: 100%;
        max-width: 300px;

        .el-dialog__body {
            text-align: center;
            color: #000;
            
            .title {
                font-size: 24px;
            }

            .desc {
                font-size: $large;
                padding: 10px 0 20px;
            }

            .btn {
                font-weight: bold;
                text-align: center;
                padding: 8px 50px;
                border-radius: 30px;
                color: #FFF;
                background-color: $brown;
                cursor: pointer;
            }
        }
    }
</style>