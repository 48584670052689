<template>
    <div class="account">
        <div class="back" @click="goBack">
            <img src="../img/icon-back.png">
        </div>
        <div class="title">
            {{ $t("Update Profile") }}
        </div>
        <div v-if="isLoading" v-loading="loading" class="text-center">
            <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
            <div class="input email-input">
                <input v-model="email" type="email" class="form-control" readonly>
            </div>
            <div class="input nickname-input">
                <input v-model="nickname" class="form-control" :class="{ 'error-input': !isNicknameValid }" id="nicknameInput" :placeholder="$t('nickname')">
                <p v-if="!isNicknameValid" class="error-text">
                    {{ $t("Can\'t be empty") }}
                </p>
            </div>
            <div class="input ic-input">
                <input v-model="ic" class="form-control" :class="{ 'error-input': !isIcValid }" id="icInput" :placeholder="$t('identity card number')">
                <p v-if="!isIcValid" class="error-text">
                    {{ $t("Please enter a valid I/C number") }}
                </p>
            </div>
            <div class="input phonenumber-input">
                <input v-model="phoneNumber" class="form-control" :class="{ 'error-input': !isPhoneNumberValid }" id="phoneNumberInput" :placeholder="$t('Phone Number (eg. +60123456789)')">
                <p v-if="!isPhoneNumberValid" class="error-text">
                    {{ $t("Please enter a valid phone number") }}
                </p>
            </div>
            <div class="input address-input">
                <input v-model="address" class="form-control" :class="{ 'error-input': !isAddressValid }" id="adressInput" :placeholder="$t('address')">
                <p v-if="!isAddressValid" class="error-text">
                    {{ $t("Can\'t be empty") }}
                </p>
            </div>
            <div class="input festival-select">
                <select v-model="festival" class="form-select">
                    <option value disabled>{{ $t("what festival do you celebrate?") }}</option>
                    <option v-for="(item, index) in festivalList" :key="index" :value="item">{{ item }}</option>
                </select>
            </div>
            <div v-if="isButtonLoading" v-loading="loading" class="update-">
                <div class="spinner-border" role="status"></div>
            </div>
            <div v-else class="update-button" @click="update">
                {{ $t("Update") }}
            </div>
        </div>

        <el-dialog v-model="isShowUpdateDialog" center :show-close="false" :close-on-click-modal="false">
            <div class="desc">
                {{ $t("Profile Updated") }}
            </div>
            <div class="btn" @click="isShowUpdateDialog = false">
                {{ $t("Okay") }}
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Account',
        data() {
            return {
                userId: localStorage.getItem("userId"),
                isLoading: true,
                email: '',
                nickname: '',
                ic: '',
                phoneNumber: '',
                address: '',
                festival: '',
                isNicknameValid: true,
                isIcValid: true,
                isPhoneNumberValid: true,
                isAddressValid: true,
                festivalList: ['Chinese New Year', 'Deepavali', 'Hari Raya'],
                isButtonLoading: false,
                isShowUpdateDialog: false,
            }
        },
        mounted () {
            this.getInfo();
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            getInfo() {
                this.$api.requestByGet('me').then((result) => {
                    this.isLoading = false;
                    if (result.success == true) {
                        this.email = result.data.email;
                        this.nickname = result.data.nickname;
                        this.ic = result.data.ic_number;
                        this.phoneNumber = result.data.phone_number;
                        this.address = result.data.address;
                        this.festival = result.data.festival;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.message);
                });
            },
            checkValid(attr, value) {
                const numberPattern = /^[+0-9]*$/;
                switch(attr) {
                    case 'nickname':
                        this.isNicknameValid = (this.nickname != '' && this.nickname != null) ? true : false;
                        break;
                    case 'ic':
                        this.isIcValid = (numberPattern.test(value) && this.ic != '') ? true : false;
                        break;
                    case 'phoneNumber':
                        this.isPhoneNumberValid = (numberPattern.test(value) && this.phoneNumber != '') ? true : false;
                        break;
                    case 'address':
                        this.isAddressValid = (this.address != '' && this.address != null) ? true : false;
                        break;
                    default:
                        break;
                }
            },
            update() {
                this.isShowUpdateDialog = true;
                return;
                if (this.isNicknameValid && this.isIcValid && this.isPhoneNumberValid && this.isAddressValid && this.festival != '') {
                    var form = {
                        'email': this.email, 
                        'ic_number': this.ic, 
                        'nickname': this.nickname, 
                        'phone_number': this.phoneNumber, 
                        'address': this.address, 
                        'festival': this.festival,
                    };
                    this.$api.requestbyPatch('app-user/' + this.userId, form).then((result) => {
                        this.isLoading = false;
                        if (result.success == true) {
                            this.isShowUpdateDialog = true;
                        } else {
                            this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isLoading = false;
                        this.$message.error(error.message);
                    });
                }
            },
        },
        watch: {
            nickname(value) {
                this.nickname = value;
                this.checkValid('nickname', value);
            },
            ic(value) {
                this.ic = value;
                this.checkValid('ic', value);
            },
            phoneNumber(value) {
                this.phoneNumber = value;
                this.checkValid('phoneNumber', value);
            },
            address(value) {
                this.address = value;
                this.checkValid('address', value);
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/account.scss';
</style>