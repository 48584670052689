<template>
    <div class="requestOtp">
        <div class="mountain-img">
            <img src="../img/mountain.png">
        </div>
        <div class="requestOtp-top">
            <div class="back" @click="goBack">
                <img src="../img/icon-back.png">
            </div>
            <div class="title">
                {{ $t("sign in") }}
            </div>
            <div class="description">
                {{ $t("Please enter your registered email and we will send you the OTP number to your inbox") }}
            </div>
            <div class="email-input">
                <input v-model="email" type="email" class="form-control" :class="{ 'error-input': !isEmailValid }" id="emailInput" :placeholder="$t('your email')">
                <p v-if="!isEmailValid" class="error-text">
                    {{ $t("Please enter a valid email address") }}
                </p>
            </div>
        </div>
        <div class="requestOtp-bottom">
            <div v-if="isButtonLoading" v-loading="loading" class="request-button">
                <div class="spinner-border" role="status"></div>
            </div>
            <div v-else class="request-button" @click="requestOtp">
                {{ $t("Request OTP") }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RequestOtp',
        data() {
            return {
                email: '',
                isEmailValid: false,
                isButtonLoading: false,
            }
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            checkEmail(value) {
                const emailPattern = /[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                this.isEmailValid = emailPattern.test(value) ? true : false;
            },
            requestOtp() {
                var form = {
                    'email': this.email,
                }
                if (this.isEmailValid) {
                    this.isButtonLoading = true;
                    this.$api.request('signin', form).then((result) => {
                        this.isButtonLoading = false;
                        if (result.success == true) {
                            localStorage.setItem("email", this.email);
                            this.$router.push("/otpVerification");
                        } else {
                            this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isButtonLoading = false;
                        this.$message.error(error.message);
                    });
                }
            },
        },
        watch: {
            email(value) {
                this.email = value;
                this.checkEmail(value);
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/requestOtp.scss';
</style>