<template>
    <div class="otp-verification">
        <div class="mountain-img">
            <img src="../img/mountain.png">
        </div>
        <div class="otp-verification-top">
            <div class="back" @click="goBack">
                <img src="../img/icon-back.png">
            </div>
            <div class="title">
                {{ $t("OTP Verification") }}
            </div>
            <div class="description">
                {{ $t("A 6 digit code has been sent to ") }}
                <span class="fw-bold">{{ email }}</span>
            </div>
            <div class="input otp-input">
                <input v-model="otp" class="form-control" :class="{ 'error-input': !isOtpValid }" id="otpInput" :placeholder="$t('OTP Number')">
                <p v-if="!isOtpValid" class="error-text">
                    {{ $t("Can\'t be empty") }}
                </p>
            </div>
        </div>
        <div class="otp-verification-bottom">
            <div v-if="isShowResend" class="resend-otp">
                {{ $t("Don't receive OTP?") }}
                <span class="brown" @click="resendOtp">
                    {{ $t("Resend OTP") }}
                </span>
            </div>
            <div v-else class="resend-otp">
                {{ $t("Resend OTP in") }}
                <span class="fw-bold">
                    {{ timerCount }}
                </span>
            </div>
            <div v-if="isButtonLoading" v-loading="loading" class="login-button">
                <div class="spinner-border" role="status"></div>
            </div>
            <div v-else class="login-button" @click="login">
                {{ $t("Log In") }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OtpVerification',
        data() {
            return {
                timerCount: 60,
                email: localStorage.getItem('email'),
                otp: '',
                isOtpValid: false,
                isButtonLoading: false,
                isShowResend: false,
            }
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            checkOtp(value) {
                this.isOtpValid = this.otp != '' ? true : false;
            },
            login() {
                if (this.isOtpValid) {
                    var form = {
                        'email': this.email,
                        'otp': this.otp,
                    }
                    this.isButtonLoading = true;
                    this.$api.request('verify-otp', form).then((result) => {
                        this.isButtonLoading = false;
                        if (result.success == true) {
                            localStorage.setItem("token", result.token);
                            localStorage.removeItem('email');
                            this.$router.push("/");
                        } else {
                            this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isButtonLoading = false;
                        this.$message.error(error.message);
                    });
                }
            },
            resendOtp() {
                var form = {
                    'email': this.email,
                }
                this.$api.request('signin', form).then((result) => {
                    if (result.success == true) {
                        this.timerCount = 60;
                        this.isShowResend = false;
                    } else {
                        this.$message.error(this.$t("Failed, please try again!"));
                    }
                }).catch(error => {
                    this.isButtonLoading = false;
                    this.$message.error(error.message);
                });
            },
        },
        computed: {
            timerCount() {
                return this.timerCount; 
            }
        },
        watch: {
            otp(value) {
                this.otp = value;
                this.checkOtp(value);
            },
            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    } else {
                        this.isShowResend = true;
                    }
                },
                immediate: true,
                deep: true,
            }
        },
    }
</script>

<style lang="scss">
    @import '../styles/otpVerification.scss';
</style>