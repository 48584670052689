<template>
    <div class="signUp">
        <div class="back" @click="goBack">
            <img src="../img/icon-back.png">
        </div>
        <div class="mountain-img">
            <img src="../img/mountain.png">
        </div>
        <div class="title">
            {{ $t("Sign Up") }}
        </div>
        <div class="description">
            {{ $t("Please fill in the required fields below and click ‘Sign Up’.") }}
        </div>
        <div class="input email-input">
            <input v-model="email" type="email" class="form-control" :class="{ 'error-input': !isEmailValid }" id="emailInput" :placeholder="$t('your email')">
            <p v-if="!isEmailValid" class="error-text">
                {{ $t("Please enter a valid email address") }}
            </p>
        </div>
        <div class="input ic-name-input">
            <input v-model="icName" class="form-control" :class="{ 'error-input': !isIcNameValid }" id="icNameInput" :placeholder="$t('full name as per ic')">
            <p v-if="!isIcNameValid" class="error-text">
                {{ $t("Can\'t be empty") }}
            </p>
        </div>
        <div class="input ic-input">
            <input v-model="ic" class="form-control" :class="{ 'error-input': !isIcValid }" id="icInput" :placeholder="$t('identity card number')">
            <p v-if="!isIcValid" class="error-text">
                {{ $t("Please enter a valid I/C number") }}
            </p>
        </div>
        <div class="input phone-input">
            <input v-model="phone" class="form-control" :class="{ 'error-input': !isPhoneValid }" id="phoneInput" :placeholder="$t('Phone Number (eg. +60123456789)')">
            <p v-if="!isPhoneValid" class="error-text">
                {{ $t("Please enter a valid phone number") }}
            </p>
        </div>
        <div class="input address-input">
            <input v-model="address" class="form-control" :class="{ 'error-input': !isAddressValid }" id="adressInput" :placeholder="$t('address')">
            <p v-if="!isAddressValid" class="error-text">
                {{ $t("Can\'t be empty") }}
            </p>
        </div>
        <div class="input festival-select">
            <select v-model="festival" class="form-select">
                <option value disabled>{{ $t("what festival do you celebrate?") }}</option>
                <option v-for="(item, index) in festivalList" :key="index" :value="item">{{ item }}</option>
            </select>
        </div>
        <div v-if="isButtonLoading" v-loading="loading" class="signup-button">
            <div class="spinner-border" role="status"></div>
        </div>
        <div v-else class="signup-button" @click="signup">
            {{ $t("sign up") }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SignUp',
        data() {
            return {
                isButtonLoading: false,
                email: '',
                icName: '',
                ic: '',
                phone: '',
                address: '',
                festival: '',
                isEmailValid: false,
                isIcValid: false,
                isIcNameValid: false,
                isPhoneValid: false,
                isAddressValid: false,
                festivalList: ['Chinese New Year', 'Deepavali', 'Hari Raya'],
            }
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            checkValid(attr, value) {
                const emailPattern = /[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                const numberPattern = /^[+0-9]*$/;

                switch(attr) {
                    case 'email':
                        this.isEmailValid = emailPattern.test(value) ? true : false;
                        break;
                    case 'icName':
                        this.isIcNameValid = this.icName != '' ? true : false;
                        break;
                    case 'ic':
                        this.isIcValid = (numberPattern.test(value) && this.ic != '') ? true : false;
                        break;
                    case 'phone':
                        this.isPhoneValid = (numberPattern.test(value) && this.phone != '') ? true : false;
                        break;
                    case 'address':
                        this.isAddressValid = this.address != '' ? true : false;
                        break;
                    default:
                        break;
                }
            },
            signup() {
                if (this.isEmailValid && this.isIcValid && this.isIcNameValid && this.isPhoneValid && this.isAddressValid && this.festival != '') {
                    var form = {
                        'email': this.email, 
                        'full_name': this.icName, 
                        'ic_number': this.ic,
                        'phone_number': this.phone,
                        'address': this.address,
                        'festival': this.festival,
                    };
                    this.isButtonLoading = true;
                    this.$api.request('app-user', form).then((result) => {
                        this.isButtonLoading = false;
                        if (result.status == true) {
                            localStorage.setItem("email", this.email);
                            this.$router.push('/otpVerification');
                        } else {
                            this.$message.error(this.$t("Failed, please try again!"));
                        }
                    }).catch(error => {
                        this.isButtonLoading = false;
                        this.$message.error(error.message);
                    });
                }
            }
        },
        watch: {
            email(value) {
                this.email = value;
                this.checkValid('email', value);
            },
            icName(value) {
                this.icName = value;
                this.checkValid('icName', value);
            },
            ic(value) {
                this.ic = value;
                this.checkValid('ic', value);
            },
            phone(value) {
                this.phone = value;
                this.checkValid('phone', value);
            },
            address(value) {
                this.address = value;
                this.checkValid('address', value);
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/signUp.scss';
</style>