window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.baseURL = 'https://crm.fairypark.asia/api/';
window.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/* instance */
const instance = window.axios.create();
instance.interceptors.request.use(function(request) {
    // let baseToken = document.querySelector('meta[name="token"]').getAttribute('content');
    let userToken = localStorage.getItem('token');
    if (userToken) {
        request.headers.Authorization = 'Bearer ' + userToken;
    }
    // } else {
    //     request.headers.Authorization = 'Bearer ' + baseToken;
    // }

    return request;
}, function(error)
{
    // error.response.data = {
    //     resp_msg: {
    //         code: 500,
    //         message: 'Server Error'
    //     }
    // };

    return error.response;
});

instance.interceptors.response.use(function(response) {
    console.log('---response---');
    console.log(response.data);
    if (response) {
        if (response.data) {
            return response.data;
        }
    }

    return null;
}, function(error)
{
    // error.response.data = {
    //     resp_msg: {
    //         code: 500,
    //         message: 'Server Error'
    //     }
    // };
    
    if (error.request.status == '401') {
        window.location.pathname = '/login';
        localStorage.removeItem("token");
        throw Error('Your session has expired!');
    }
    console.log('bcd')
    console.log(error);
    error.response.data.success = false;
    return error.response.data;
});

/* api */
let api = {};
api.request = function(method, data)
{
    return instance.post(method, data);
};

api.requestbyPatch = function(method, data)
{
    return instance.patch(method, data);
};

api.requestbyDelete = function(method, data)
{
    return instance.delete(method, data);
};

api.requestByGet = function (method, data = {}) {
    if (Object.entries(data).length > 0) {
      var esc = encodeURIComponent;
      var query = Object.keys(data).map(k => esc(k) + '=' + esc(data[k])).join('&');
      method = method + '?' + query;
    }
  
    return instance.get(method);
  };

api.getJson = function(jsonName)
{
    return window.axios.get(`${jsonName}.json`, { baseURL: './json/' })
        .then((response) => { return response.data; })
        .catch((error) =>
        {
            throw error.response.data;
        });
}

export default api